import React from 'react';



import { GoogleApiWrapper } from 'google-maps-react';
import Geocode from "react-geocode";
import PlacesAutocomplete from 'react-places-autocomplete';
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min';
import mtzApis from '../../../services';
import { GOOGLE_API_KEY } from '../../../services/config';

const { companyService, networkService } = mtzApis;

function HomeSearchBoxNew({ }) {
  let params = new URLSearchParams(window.location.search);
  let initialKeyword = params.get("keyword") ? decodeURIComponent(params.get("keyword")) : '';
  let [suggestions, setSuggestions] = React.useState({});
  let [k, setK] = React.useState(initialKeyword);
  let [focused, setFocused] = React.useState(false);
  let [gAddr, setGAddr] = React.useState({ country: params.country, city: params.city, zipCode: params.zipCode });
  const searchBox = React.useRef();
  const searchBox1stChild = React.useRef();
  const searchRes = React.useRef();

  React.useEffect(() => {
    const searchTimeout = setTimeout(() => autoComplete(k), 100);

    const handleClickOutside = document.addEventListener("mousedown", e => {
      if (searchRes && searchRes.current && !searchRes.current.contains(e.target))
        if (searchBox && searchBox.current && !searchBox.current.contains(e.target))
          setFocused(false);

      if (searchBox && searchBox.current && searchBox.current.contains(e.target))
        setFocused(true);
    });

    return () => {
      clearTimeout(searchTimeout);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [k, window.location.search, gAddr]);

  const onSearch = () => {
    if (!k || k.trim() === '') {
      return;
    }
    k = k.trim()
    k = encodeURIComponent(k);

    let query = new URLSearchParams();
    query.set('keyword', k);
    query.set('type', params.get("type") || 'COMPANY');

    if (gAddr) {
      if (gAddr.country) query.set('country', gAddr.country);
      if (gAddr.city) query.set('city', gAddr.city);
      if (gAddr.zipCode) query.set('zipCode', gAddr.zipCode);
    } else {
      query.delete('country');
      query.delete('city');
      query.delete('zipCode');
    }

    window.location.href = (`/search?${query}`);
  };

  const autoComplete = async key => {
    if (key && key.trim() != '') {
      key = encodeURIComponent(key);
      let query1 = new URLSearchParams();
      query1.set('keyword', key);
      query1.set('skip', 0);
      query1.set('limit', 4);
      if (gAddr) {
        if (gAddr.country) query1.set('country', gAddr.country);
        if (gAddr.city) query1.set('city', gAddr.city);
        if (gAddr.zipCode) query1.set('zipCode', gAddr.zipCode);
      } else {
        query1.delete('country');
        query1.delete('zipCode');
        query1.delete('city');
      }

      let query2 = new URLSearchParams(Object.fromEntries(query1));
      query2.delete('skip');
      query2.delete('limit');
      let ajaxes = [
        companyService.getCompanySearches(query1),
        companyService.getCompanySearchStats(query2),
        companyService.getProductSearches(query1),
        companyService.getProductSearchStats(query2),
        companyService.getRfqSearches((() => {
          let q = new URLSearchParams(query1);
          if (q.get('country')) {
            q.set("ownerCountry", q.get('country'));
            q.delete('country');
          }
          if (q.get('city')) {
            q.set("ownerCity", q.get('city'));
            q.delete('city');
          }
          if (q.get('zipCode')) {
            q.set("ownerZipCode", q.get('zipCode'));
            q.delete('zipCode');
          }
          return q;
        })()),
        companyService.getRfqSearchStats((() => {
          let q = new URLSearchParams(query2);
          if (q.get('country')) {
            q.set("ownerCountry", q.get('country'));
            q.delete('country');
          }
          if (q.get('city')) {
            q.set("ownerCity", q.get('city'));
            q.delete('city');
          }
          if (q.get('zipCode')) {
            q.set("ownerZipCode", q.get('zipCode'));
            q.delete('zipCode');
          }
          return q;
        })()),
        networkService.getCompanyGroupSearches(query1),
        networkService.getCompanyGroupSearchStats(query1)
      ];

      let data = await Promise.allSettled(ajaxes);
      data = data.map(d => d.value);
      let [companySearches, companySearchStats, productSearches, productSearchStats, rfqSearches, rfqSearchStats, companyGroupSearches, companyGroupSearchStats] = data;

      if (productSearches) {
        let companyIds = productSearches.map(s => s.product.companyId);
        let companies = await companyService.getCompanies(new URLSearchParams(`companyIds=${companyIds}`));
        if (companies) {
          let compMap = {};
          companies.forEach(c => compMap[c.id] = c);
          productSearches.forEach(s => s.company = compMap[s.product.companyId] || {});
        }
      }

      suggestions = { ...suggestions, companySearches, companySearchStats, productSearchStats, productSearches, rfqSearches, rfqSearchStats, companyGroupSearches, companyGroupSearchStats };
      setSuggestions(suggestions);
    } else {
      setTimeout(() => setSuggestions({}), 200);
    }
  };

  return (
    <div ref={searchBox} onFocus={() => setFocused(true)} className={'w-100 bg-white' + (focused ? ' top-0 left-0 position-absolute z-index-10000 d-flex justify-content-center py-2' : '')}>
      <div className={'px-3 d-flex flex-column flex-md-row w-100 mtz-gap-4 align-items-center' + (focused ? ' shadow-lg w-md-80' : '')}>
        {
          focused &&
          <>
            <AddrSearchGoogleWrapper onChange={addr => setGAddr(addr)} />
            <span className='d-none d-md-inline-block text-muted'>|</span>
          </>
        }

        <div ref={searchBox1stChild} className='flex-fill w-100 vw-100'>
          <div className='d-flex mtz-gap-4 m-1 p-1 rounded bg-light'>
            <input
              onKeyDown={e => {
                if (e.key === 'Enter')
                  onSearch();
              }}
              className='form-control border-0 my-1'
              placeholder="Type to search"
              onChange={e => setK(e.target.value)}
              style={{ height: '40px' }}
              value={k || ''}
            />

            <button onClick={onSearch} className='fa fa-search btn text-primary m-1 rounded-circle'></button>
          </div>

          <div ref={searchRes} style={{ width: searchBox1stChild && searchBox1stChild.current ? searchBox1stChild.current.clientWidth : 'auto' }}
            className={'text-left vh-75 overflow-auto shadow my-1 mtz-rounded-16 position-absolute bg-white' + (k && focused ? '' : ' d-none')}>
            <div className='text-right m-2'>
              <button onClick={() => setFocused(false)} className='btn btn-sm btn-secondary rounded'>
                <span className='d-flex align-items-center mtz-gap-4'>
                  close <span className='fa fa-close'></span>
                </span>
              </button>
            </div>

            {
              Object.keys(suggestions).includes('companySearches') &&
              suggestions.companySearchStats && suggestions.companySearchStats.all && suggestions.companySearchStats.all.count !== 0 &&
              <div className='m-2'>
                <div display="flex">
                  <div>
                    <h6><b>Companies found: {suggestions.companySearchStats && suggestions.companySearchStats.all ? suggestions.companySearchStats.all.count : 0}</b></h6>
                  </div>
                  <div ml={2} style={{ lineHeight: 1 }}>
                    <small>
                      <NavLink onClick={() => setFocused(false)} to={() => {
                        let query = new URLSearchParams();
                        query.set('keyword', k);
                        query.set('type', 'COMPANY');
                        if (gAddr) {
                          if (gAddr.country) query.set('country', gAddr.country);
                          if (gAddr.city) query.set('city', gAddr.city);
                          if (gAddr.zipCode) query.set('zipCode', gAddr.zipCode);
                        } else {
                          query.delete('country');
                          query.delete('city');
                          query.delete('zipCode');
                        }
                        return `/search?${query}`;
                      }}>
                        View All
                      </NavLink>
                    </small>
                  </div>
                </div>
                <div>
                  {
                    suggestions.companySearches.map(s => (
                      <div className="text-left text-wrap py-1 px-2" key={s.company.id}>
                        <NavLink to={`/profile/${s.company.profileName}`}>- {s.company.name}</NavLink>
                      </div>
                    ))
                  }
                </div>
              </div>
            }

            {
              Object.keys(suggestions).includes('productSearches') &&
              suggestions.productSearchStats && suggestions.productSearchStats.all && suggestions.productSearchStats.all.count !== 0 &&
              <div className='m-2'>
                <div display="flex">
                  <div>
                    <h6><b>Products found: {suggestions.productSearchStats && suggestions.productSearchStats.all ? suggestions.productSearchStats.all.count : 0}</b></h6>
                  </div>
                  <div ml={2} style={{ lineHeight: 1 }}>
                    <small>
                      <NavLink onClick={() => setFocused(false)} to={() => {
                        let query = new URLSearchParams();
                        query.set('keyword', k);
                        query.set('type', 'PRODUCT');
                        if (gAddr) {
                          if (gAddr.country) query.set('country', gAddr.country);
                          if (gAddr.city) query.set('city', gAddr.city);
                          if (gAddr.zipCode) query.set('zipCode', gAddr.zipCode);
                        } else {
                          query.delete('country');
                          query.delete('city');
                          query.delete('zipCode');
                        }
                        return `/search?${query}`;
                      }}>
                        View All
                      </NavLink>
                    </small>
                  </div>
                </div>
                <div>
                  {
                    suggestions.productSearches.map(s => (
                      <div className="text-left text-wrap py-1 px-2" key={s.id}>
                        <NavLink to={`/products/${s.company.profileName}/${s.product.sku}`}>- {s.product.name}</NavLink>
                      </div>
                    ))
                  }
                </div>
              </div>
            }

            {
              Object.keys(suggestions).includes('rfqSearches') &&
              suggestions.rfqSearchStats && suggestions.rfqSearchStats.all && suggestions.rfqSearchStats.all.count !== 0 &&
              <div className='m-2'>
                <div display="flex">
                  <div>
                    <h6><b>RFQs found: {suggestions.rfqSearchStats && suggestions.rfqSearchStats.all ? suggestions.rfqSearchStats.all.count : 0}</b></h6>
                  </div>
                  <div ml={2} style={{ lineHeight: 1 }}>
                    <small>
                      <NavLink onClick={() => setFocused(false)} to={() => {
                        let query = new URLSearchParams();
                        query.set('keyword', k);
                        query.set('type', 'RFQ');
                        if (gAddr) {
                          if (gAddr.country) query.set('country', gAddr.country);
                          if (gAddr.city) query.set('city', gAddr.city);
                          if (gAddr.zipCode) query.set('zipCode', gAddr.zipCode);
                        } else {
                          query.delete('country');
                          query.delete('city');
                          query.delete('zipCode');
                        }
                        return `/search?${query}`;
                      }}>
                        View All
                      </NavLink>
                    </small>
                  </div>
                </div>
                <div>
                  {
                    suggestions.rfqSearches.map(s => (
                      <div className="text-left text-wrap py-1 px-2" key={s.id}>
                        <NavLink to={`/rfqs/${s.rfq.id}`}>- {s.rfq.title}</NavLink>
                      </div>
                    ))
                  }
                </div>
              </div>
            }

            {
              suggestions.companyGroupSearches &&
              suggestions.companyGroupSearchStats && suggestions.companyGroupSearchStats.all && suggestions.companyGroupSearchStats.all.count !== 0 &&
              <div className='m-2'>
                <div display="flex">
                  <div>
                    <h6><b>Company groups found: {suggestions.companyGroupSearchStats && suggestions.companyGroupSearchStats.all ? suggestions.companyGroupSearchStats.all.count : 0}</b></h6>
                  </div>
                  <div ml={2} style={{ lineHeight: 1 }}>
                    <small>
                      <NavLink onClick={() => setFocused(false)} to={() => {
                        let query = new URLSearchParams();
                        query.set('keyword', k);
                        query.set('type', 'GROUP');
                        if (gAddr) {
                          if (gAddr.country) query.set('country', gAddr.country);
                          if (gAddr.city) query.set('city', gAddr.city);
                          if (gAddr.zipCode) query.set('zipCode', gAddr.zipCode);
                        } else {
                          query.delete('country');
                          query.delete('city');
                          query.delete('zipCode');
                        }
                        return `/search?${query}`;
                      }}>
                        View All
                      </NavLink>
                    </small>
                  </div>
                </div>
                <div>
                  {
                    suggestions.companyGroupSearches.map(s => (
                      <div className="text-left text-wrap py-1 px-2" key={s.id}>
                        <NavLink to={`/groups/${s.companyGroup.profileName}`}>- {s.companyGroup.name}</NavLink>
                      </div>
                    ))
                  }
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  );
}

function AddrSearch({ onChange }) {
  let [k, setK] = React.useState('');

  React.useEffect(() => {
    let params = Object.fromEntries(new URLSearchParams(window.location.search));
    k = `${params.city ? params.city + ' ' : '' || ''}${params.zipCode ? params.zipCode + ' ' : ''}${params.country ? params.country + ' ' : ''}`;
    console.log(k)
    setK(k);
  }, [window.location.search]);

  const getGoogleAddress = async address => {
    Geocode.setApiKey(GOOGLE_API_KEY);
    Geocode.setLanguage("en");

    try {
      let response = await Geocode.fromAddress(address);
      let googleAddress = {
        country: '',
        zipCode: '',
        state: '',
        city: '',
        address: '',
      };
      for (let i = 0; i < response.results[0].address_components.length; i++) {
        let componentName = response.results[0].address_components[i].types[0];
        switch (componentName) {
          case 'country':
            googleAddress.country = response.results[0].address_components[i].short_name;
            break;
          case 'postal_code':
            googleAddress.zipCode = response.results[0].address_components[i].short_name;
            break;
          case "administrative_area_level_1":
            googleAddress.state = response.results[0].address_components[i].short_name;
            break;
          case 'locality':
            googleAddress.city = response.results[0].address_components[i].short_name;
            break;
          case 'route':
            googleAddress.streetName = response.results[0].address_components[i].short_name;
            break;
          case 'street_number':
            googleAddress.streetNumber = response.results[0].address_components[i].short_name;
            break;
        }
      }

      if (googleAddress.city === googleAddress.state)
        googleAddress.state = null

      const { lat, lng } = response.results[0].geometry.location;
      googleAddress.lat = lat;
      googleAddress.lng = lng;

      googleAddress.address = (googleAddress.streetNumber || '') + " " + (googleAddress.streetName || '');

      return googleAddress;
    } catch (e) {
      // console.log(e);
    }
  };

  const search = async val => {
    setK(val);
    let result = await getGoogleAddress(val);
    if (onChange)
      onChange(result);
    return result;
  };

  const handleSelect = async addr => {
    let result = await search(addr);
    if (!result)
      return;

    k = `${result.city ? result.city + ' ' : '' || ''}${result.zipCode ? result.zipCode + ' ' : ''}${result.country ? result.country + ' ' : ''}`;
    setK(k);
  };

  return (
    <PlacesAutocomplete
      value={k}
      onChange={val => search(val)}
      onSelect={handleSelect}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div className='vw-100 vw-md-auto'>
          <div className='d-flex mtz-gap-4 align-items-center p-1 m-1 rounded bg-primary'>
            <span className='mx-1 text-white fa fa-map-marker'></span>
            <input
              {...getInputProps({
                placeholder: "country/city/zipcode",
                className: 'w-100 form-control border-0'
              })}
            />
          </div>

          {
            !!suggestions && suggestions.length > 0 &&
            <div className='position-absolute z-index-1 text-left mtz-card border my-2'>
              {loading && <div>Loading...</div>}
              {suggestions.map((suggestion, idx) => {
                return (
                  <div className='mtz-cursor-pointer'
                    {...getSuggestionItemProps(suggestion, {
                    })}
                    key={suggestion.placeId}>
                    <span key={idx}>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          }
        </div>
      )}
    </PlacesAutocomplete>
  );
}

const AddrSearchGoogleWrapper = GoogleApiWrapper({
  apiKey: GOOGLE_API_KEY,
  libraries: ["places"],
})(AddrSearch);

export default HomeSearchBoxNew;
